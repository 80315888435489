<template>
<section class="wrapper bg-light">
	<div class="container py-4 py-md-4">
		<v-card elevation="0"
						class="mt-4 mb-8"
						v-if="step==1">

			<h3 class="pt-3"
					:style="detail.solde > 0 ? 'color:green' : 'color:red'"
					v-html="' &nbsp; '+$t('unvest1')" />


			<v-card-title>{{$t('invest5')}}</v-card-title>
			<v-card-text>
				<table style="width:100%">
					<tr>
						<td>{{$t('invest6')}} <br /> <b>{{detail.date_maj}}</b></td>
						<td>{{$t('invest7')}} <br /><b v-html="monetary_aspect(parseFloat(detail.valeur_maj))" /></td>
					</tr>
					<tr>
						<td>{{$t('invest8')}}<br /><b v-html="monetary_aspect(detail.anticipation)" /></td>
						<td>{{$t('invest9')}}<br /> <b v-html="monetary_aspect(price)" /></td>
					</tr>
				</table>
			</v-card-text>

			<hr class="my-2" />
			<v-card-title>{{$t('invest44')}}</v-card-title>
			<v-card-text>
				<v-text-field v-model="email"
											style="font-size:60%"
											persistent-hint
											:hint="$t('invest12')"
											:label="$t('invest11')">
				</v-text-field>

				<v-text-field v-model="clepublique"
											:rules="[rules.counter]"
											style="font-size:60%"
											persistent-hint
											:hint="$t('invest14')"
											:label="$t('invest13')">
				</v-text-field>
				<br />
				<v-text-field v-model="field_euro"
											outlined
											@blur="convert_berlcoin"
											:label="$t('invest15')"></v-text-field>
				{{$t('invest16')}} <b>{{quantity}}</b> fractions
			</v-card-text>
			<hr class="my-0" />




			<v-card-title> {{$t('unvest3')}}</v-card-title>
			<v-card-text>
				{{$t('unvest2')}}
				<v-otp-input v-model="otp"
										 length="6"></v-otp-input>
				<i style="font-size:80%">{{$t('unvest5')}}</i>
			</v-card-text>



			<div v-if="detail.solde > 0 ">

				<v-card-actions>

					<div @click="revendre()"
							 v-if="(clepublique.length == 56 || email != '')  && otp.length ==6"
							 style="background-color:green; color:white"
							 class="btn  success mx-auto">{{$t('unvest6')}}</div>

					<a style="background-color:purple; color:white"
						 href="https://wallet.fraction.re"
						 v-else
						 class="btn m-auto py-1 mb-3  "> {{$t('unvest4')}}</a>


				</v-card-actions>
			</div>
		</v-card>
		<v-card class="mx-auto mt-1 mb-8 p-3"
						v-if="step==2"
						max-width="450">
			<h3 v-html="$t('unvest7')" />
			<p v-html="$t('invest46')" />

			<v-card-actions>
				<a class="btn mt-8 mb-5 mx-auto"
					 style="background-color:green;color:white"
					 :href="'/'+$i18n.locale+'/invest'"
					 v-html="$t('invest49')" />
			</v-card-actions>

		</v-card>
	</div>
</section>
</template>


<script>
export default {
	name: 'sell',
	components: {},
	props: {},
	data: () => ({
		step: 1,
		detail: [],
		quantity: 0,
		price: 0,
		credit: 0,
		reward: 0,
		total: 0,
		totaleuro: 0,
		win: 0,
		field_euro: 0,
		otp: "",

		price: 0,
		asset_code: "Berlcoin",
		field_euro: 100,
		clepublique: "",
		naissance: "",
		email: '',
		asset_code_fceuro: "€",
		term: 0,
		sizes: [
			'0', '2', '3', '5', '7', '10',
		],
		rules: {
			required: value => !!value || 'Required.',
			counter: value => value.length == 56 || 'Une clé à 56 characteres',
			email: value => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return pattern.test(value) || 'Invalid e-mail.'
			},
		},
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		this.$i18n.locale = this.$route.params.lang

		this.detail.ville = 'BERLIN'
		this.detail.adresse = "Zone A"
		this.detail.siren = '911 774 206'
		this.detail.raison_sociale = "Fraction Real Estate"
		this.detail.ppadresse = "56 rue monge  "
		this.detail.ppcode_postal = "75005"
		this.detail.ppville = "PARIS"
		this.detail.telephone = "+337 66 46 17 28"
		this.detail.email = "Berlcoin@fraction.re"
		this.detail.solde = "10000"
		this.detail.anticipation = "11.5"
		this.detail.date_maj = "2022-01-01"
		this.detail.valeur_maj = 100

		let date1 = new Date(this.detail.date_maj);
		let date2 = new Date();
		let Diff_temps = date2.getTime() - date1.getTime();
		let Diff_jours = Diff_temps / (1000 * 3600 * 24);


		this.price = parseFloat(this.detail.valeur_maj) + (Diff_jours * (parseFloat(this.detail.valeur_maj) * parseFloat(this.detail.anticipation) / 100) / 365);
		this.quantity = this.field_euro / this.price;
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {
		revendre() {
			this.opt = [];
			this.opt["api"] = "modif_contract_berlcoin"
			this.opt["debug"] = false
			this.opt["cle"] = this.clepublique
			this.opt["email"] = this.email
			this.opt["field_euro"] = this.field_euro
			this.opt["antispam"] = this.otp


			this.opt["token_asset_code"] = this.id
			this.$store.dispatch('post', this.opt).then(response => {
				if (response != undefined) this.step = 2;
			})

		},
		convert_berlcoin: function () {
			this.quantity = Number(this.field_euro) / Number(this.price);
			this.verif_nb_berlcoin();
		},
		verif_nb_berlcoin: function () {
			this.quantity = (Math.round(this.quantity * 100000) / 100000).toFixed(5);
			this.field_euro = (Math.round(this.field_euro * 100) / 100).toFixed(2);
			let taux = 1;

			//console.log(this.term)
			if (this.term == "0") taux = 0;
			if (this.term == "2") taux = 1.9;
			if (this.term == "3") taux = 5.6;
			if (this.term == "5") taux = 14.4;
			if (this.term == "7") taux = 28.5;
			if (this.term == "10") taux = 46;

			this.credit = this.quantity;
			this.reward = (Number(this.quantity) * taux / 100).toFixed(2);
			this.total = (Number(this.reward) + Number(this.quantity)).toFixed(2);
			this.totaleuro = (Number(this.total) * Number(this.price)).toFixed(2);
			this.win = (Number(this.reward) * Number(this.price)).toFixed(2);

		},
	},
	watch: {}
}
</script>

<style scoped>
</style>
